import { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { format } from 'date-fns'

import api from 'utils/api'

const CompanyContext = createContext({})

export const CompanyProvider = ({ children }) => {
	const router = useRouter()
	const [company, setCompany] = useState({})
	const [cat, setCat] = useState({})
	const [sup, setSup] = useState({})
	const [prod, setProd] = useState({})
	const [ship, setShip] = useState({})
	const [members, setMembers] = useState({})
	const [loading, setLoading] = useState(true)
	const [messages, setMessages] = useState({})
	const [tokens, setTokens] = useState(() => {
		if (Cookies.get('token') && Cookies.get('refresh')) {
			return true
		} else {
			return false
		}
	})
	const need_company = Cookies.get('need_company')
		? JSON.parse(Cookies.get('need_company'))
		: false

	// useEffect(() => {
	// 	if (tokens && !need_company) {
	// 		userCompanies()
	// 		getUserCompany()
	// 		companyCategories()
	// 		companySupplier()
	// 		companyProducts()
	// 		companyShipments()
	// 		lowInStock()
	// 	}
	// }, [loading])

	const url = process.env.NEXT_PUBLIC_API_URL

	async function getUserCompany() {
		let res = await api.get('company/current/')

		if (res.status === 200) {
			setCompany(res.data)
		}
	}

	const lowInStock = async () => {
		let res = await api.get('inventory/lowstock/')

		if (res.status === 200) {
			if (res.data.length > 0) {
				setMessages({
					message: `Low In Stock!`,
					data: res.data,
					color: 'yellow',
					badge: 'Info',
				})
				setLoading(false)
			}
		}
	}

	const chooseCompany = async (company) => {
		setCompany(company)
		let response = await api.post('/company/choose-company/', { company: company.name })
		if (response.status === 200) {
			setCompany(response.data)
			router.push('/admin/dashboard')
		}
	}

	const companyShipments = async () => {
		let res = await api.get('inventory/shipments/')
		if (res.status === 200) {
			setShipment(res.data)
			setLoading(false)
		}
	}

	const companyProducts = async () => {
		let res = await api.get('inventory/products/')

		if (res.status === 200) {
			setProducts(res.data)
			setLoading(false)
		}
	}

	const companyCategories = async () => {
		let res = await api.get('inventory/categories/')

		if (res.status === 200) {
			setCategories(res.data)
			setLoading(false)
		}
	}

	// Invitations
	const sendInvitation = async (data) => {
		let res = await api.post(`company/invite/`, {
			name: data.name,
			email: data.email,
			role: data.role,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Sent Invitation!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.push('/admin/members/')
		}
	}

	const getInvitation = async (unique_key) => {
		let res = await axios.get(`${url}company/invitation/${unique_key}/`)

		if (res.status === 200) {
			if (res.data.status === 'ACCEPTED') {
				router.replace('/auth/login')
			}
			return res.data
		}

		return {}
	}

	const acceptInvitation = async (invitation) => {
		let res = await axios.put(`${url}company/accept/${invitation.id}/`, {
			status: 'ACCEPTED',
		})

		if (res.status === 200) {
			setMessages({
				message: `Invitation Accepted`,
				color: 'emerald',
				badge: 'Success',
			})
			router.push({
				pathname: `/auth/register-accountant`,
				query: {
					name: `${invitation.name}`,
					email: `${invitation.email}`,
				},
			})
		}
	}

	const companyMembers = async () => {
		let res = await api.get('company/members/')

		if (res.status === 200) {
			setMembers(res.data)
			setLoading((loading) => !loading)
		}
	}

	const deleteInvitation = async (id) => {
		let res = await api.delete(`company/deleteinvitation/${id}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Removed Member!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// User Companies
	const userCompanies = async () => {
		let res = await api.get('company/companies/')

		if (res.status === 200) {
			setCompanies(res.data)
			setLoading(false)
		}
	}

	// Company Companies
	const companySupplier = async () => {
		let res = await api.get('inventory/suppliers/')

		if (res.status === 200) {
			setSuppliers(res.data)
			setLoading(false)
		}
	}

	// Create
	const createCompany = async (e) => {
		e.preventDefault()

		let res = await api.post(`company/create/`, {
			name: e.target.name.value,
			license: e.target.license.value,
			based: e.target.based.value,
			size: e.target.size.value,
			accounting_year_start: e.target.accounting_year_start.value,
			accounting_year_end: e.target.accounting_year_end.value,
			founded_date: e.target.founded_date.value,
		})

		if (res.status === 201) {
			// create the state for this user
			// This doesn't concern the frontEnd!
			let state = await api.get('auth/createstate/')

			setMessages({
				message: `Successfully Created Company!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.push('/admin/companies')
		} else {
			setMessages({
				message: "Something Went Wrong! Couldn't create your company",
				color: 'red',
				badge: 'Error',
			})
		}
	}

	const createCategory = async (e) => {
		e.preventDefault()

		let res = await api.post(`inventory/createcategory/`, {
			name: e.target.name.value,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Category!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/category/all')
		}
	}

	const category = async (id) => {
		let res = await api.get(`inventory/category/${id}/`)

		if (res.status === 200) {
			setCat(res.data)
		}
	}

	const editCategory = async (data) => {
		let res = await api.put(`inventory/editcategory/${parseInt(data.catId)}/`, {
			name: data.name === '' ? data.category.name : data.name,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Category!`,
				color: 'emerald',
				badge: 'Success',
			})
			setCat({})
			setLoading((loading) => !loading)
			router.push('/admin/category/all')
		}
	}

	const deleteCategory = async (id) => {
		let res = await api.delete(`inventory/deletecategory/${parseInt(id)}`)

		if (res.status === 200) {
			setMessages({
				message: `Successfully Deleted Category!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	const createSupplier = async (data) => {
		let res = await api.post(`inventory/createsupplier/`, {
			name: data.name,
			phone: data.phone,
			email: data.email,
			desc: data.desc,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Supplier!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/suppliers/')
		}
	}

	const supplier = async (id) => {
		let res = await api.get(`inventory/supplier/${parseInt(id)}`)

		if (res.status === 200) {
			setSup(res.data)
		}
	}

	const editSupplier = async (data) => {
		let res = await api.put(`inventory/editsupplier/${parseInt(data.supId)}/`, {
			name: data.name === '' ? data.supplier.name : data.name,
			phone: data.phone === '' ? data.supplier.phone : data.phone,
			email: data.email === '' ? data.supplier.email : data.email,
			desc: data.desc === '' ? data.supplier.desc : data.desc,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Supplier!`,
				color: 'emerald',
				badge: 'Success',
			})
			setSup({})
			setLoading((loading) => !loading)
			router.push('/admin/suppliers/')
		}
	}

	const deleteSupplier = async (id) => {
		let res = await api.delete(`inventory/deletesupplier/${parseInt(id)}`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Supplier!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Products
	const createProduct = async (data) => {
		let formData = new FormData()
		formData.append('image', data.image[0])
		formData.append('name', String(data.name))
		formData.append('added_at', format(new Date(data.added_at), 'yyyy-MM-dd'))
		formData.append('desc', String(data.desc))
		formData.append('cost_price', parseFloat(data.cost_price))
		formData.append('unit_price', parseFloat(data.unit_price))
		formData.append('stock', parseInt(data.stock))
		formData.append('low_stock_warning_limit', parseInt(data.low_stock_warning_limit))
		formData.append('supplier', parseInt(data.supplier))
		formData.append('category', parseInt(data.category))

		let res = await api.post(`inventory/createproduct/`, formData)

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Product!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/products/')
		}
	}

	const product = async (id) => {
		let res = await api.get(`inventory/product/${parseInt(id)}/`)

		if (res.status === 200) {
			setProd(res.data)
		}
	}

	const editProduct = async (data) => {
		let payload = {
			id: data.product.id,
			name: data.name === '' ? data.product.name : data.name,
			added_at:
				data.added_at === ''
					? data.product.added_at
					: format(new Date(data.added_at), 'yyyy-MM-dd'),
			desc: data.desc === '' ? data.product.desc : data.desc,
			cost_price:
				data.cost_price === ''
					? parseFloat(data.product.cost_price)
					: parseFloat(data.cost_price),
			unit_price:
				data.unit_price === ''
					? parseFloat(data.product.unit_price)
					: parseFloat(data.unit_price),
			stock: data.stock === '' ? parseInt(data.product.stock) : parseInt(data.stock),
			low_stock_warning_limit:
				data.low_stock_warning_limit === ''
					? parseInt(data.product.low_stock_warning_limit)
					: parseInt(data.low_stock_warning_limit),
			supplier:
				data.supplier === '' ? parseInt(data.product.supplier) : parseInt(data.supplier),
			category:
				data.category === '' ? parseInt(data.product.category) : parseInt(data.category),
			barcode: data.barcode === '' ? data.product.barcode : data.barcode,
		}

		let res = await api.put(`inventory/editproduct/${parseInt(data.prodId)}/`, payload)

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Product!`,
				color: 'emerald',
				badge: 'Success',
			})
			setProd({})
			setLoading((loading) => !loading)
			router.push('/admin/products/')
		}
	}

	const deleteProduct = async (id) => {
		let res = await api.delete(`inventory/deleteproduct/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Product!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Shipment
	const createShipment = async (data) => {
		let res = await api.post(`inventory/createshipment/`, data)

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Shipment!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/shipments/')
		}
	}

	const shipment = async (id) => {
		let res = await api.get(`inventory/shipment/${parseInt(id)}/`)

		if (res.status === 200) {
			setShip(res.data)
		}
	}

	const editShipment = async (data) => {
		let res = await api.put(`inventory/editshipment/${parseInt(data.shipId)}/`, {
			arrived_date:
				data.arrived_date === ''
					? format(new Date(data.ship.arrived_date), 'yyyy-MM-dd')
					: format(new Date(data.arrived_date), 'yyyy-MM-dd'),
			ordered_date:
				data.ordered_date === ''
					? format(new Date(data.ship.ordered_date), 'yyyy-MM-dd')
					: format(new Date(data.ordered_date), 'yyyy-MM-dd'),
			tax: data.tax === '' ? parseInt(data.ship.tax) : parseInt(data.tax),
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Product!`,
				color: 'emerald',
				badge: 'Success',
			})
			setShip({})
			setLoading((loading) => !loading)
			router.push('/admin/shipments/')
		}
	}

	const restock = async (data) => {
		let res = await api.post(`inventory/restock/`, {
			shipment: parseInt(data.shipment),
			product: parseInt(data.product),
			amount: parseInt(data.amount),
			date: data.date,
		})

		if (res.status === 201) {
			setLoading((loading) => !loading)
			router.push('/admin/products/')
		}
	}

	const contextData = {
		cat,
		sup,
		prod,
		ship,
		messages,
		members,
		company,
		chooseCompany,
		companyCategories,
		companyProducts,
		companyShipments,
		companySupplier,
		getUserCompany,
		sendInvitation,
		getInvitation,
		acceptInvitation,
		createCompany,
		category,
		userCompanies,
		companyMembers,
		deleteInvitation,
		createCategory,
		editCategory,
		deleteCategory,
		editSupplier,
		createSupplier,
		supplier,
		deleteSupplier,
		createProduct,
		product,
		editProduct,
		deleteProduct,
		createShipment,
		setMessages,
		shipment,
		editShipment,
		restock,
	}

	return <CompanyContext.Provider value={contextData}>{children}</CompanyContext.Provider>
}

export const useCompany = () => useContext(CompanyContext)

export default CompanyContext
