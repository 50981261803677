import React from "react";

export default function PageChange(props) {
	return (
		<div>
			<div className='flex w-screen h-screen items-center justify-center mx-auto max-w-sm text-center'>
				<div className='block mb-4'>
					<i className='fas fa-circle-notch animate-spin text-slate-800 mx-auto text-6xl'></i>
				</div>
			</div>
		</div>
	);
}
