import { createContext, useState, useEffect, useContext } from 'react'

const TransactionProductContext = createContext({})

export const TransactionProductProvider = ({ children }) => {
	const [transactionItems, setTransactionItems] = useState([])
	const [paymentItems, setPaymentItems] = useState([])
	const [selectedProduct, setSelectedProduct] = useState({})
	const [totalPrice, setTotalPrice] = useState(0)
	const [transactionItem, setTransactionItem] = useState({})

	const calculateTotalPrice = () => {
		const price = transactionItems
			.map((item) => {
				return item.quantity ? parseInt(item.product.unit_price) * item.quantity : 0
			})
			.reduce((prevItem, currItem) => prevItem + currItem, 0)
		setTotalPrice(price)
		return price
	}

	const contextData = {
		transactionItems,
		selectedProduct,
		totalPrice,
		transactionItem,
		setTotalPrice,
		setSelectedProduct,
		setTransactionItems,
		setTransactionItem,
		calculateTotalPrice,
		paymentItems,
		setPaymentItems,
	}

	return (
		<TransactionProductContext.Provider value={contextData}>
			{children}
		</TransactionProductContext.Provider>
	)
}

export const useTransactionProduct = () => useContext(TransactionProductContext)

export default TransactionProductContext
