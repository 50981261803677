// api.js
import Axios from 'axios'
import Cookies from 'js-cookie'

let token = Cookies.get('token') ? Cookies.get('token') : null
const refreshToken = Cookies.get('refresh') ? Cookies.get('refresh') : null

let urls = {
	test: `http://localhost:8000/api/`,
	development: process.env.NEXT_PUBLIC_API_URL,
	production: process.env.NEXT_PUBLIC_API_URL,
}

export const publicAPI = Axios.create({
	baseURL: urls[process.env.NODE_ENV],
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
})

const api = Axios.create({
	baseURL: urls[process.env.NODE_ENV],
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	},
})

// const requestIntercept = api.interceptors.request.use(async (req) => {
// 	if (!token) {
// 		if (Cookies.get('token')) {
// 			token = Cookies.get('token') ? Cookies.get('token') : null
// 			req.headers.Authorization = `Bearer ${token}`
// 		} else {
// 			return req
// 		}
// 	}

// 	const user = jwt_decode(token)
// 	const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

// 	const refreshIsExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

// 	if (!isExpired) return req

// 	// if the refresh token is expired remove the tokens
// 	// cos there is no use for them anymore
// 	// if (!refreshIsExpired) {
// 	// 	Cookies.remove('token')
// 	// 	Cookies.remove('refresh')
// 	// 	return req
// 	// }

// 	console.log(refreshIsExpired)

// 	if (refreshToken && refreshIsExpired) {
// 		let res = await Axios.post(`${urls[process.env.NODE_ENV]}auth/token/refresh/`, {
// 			refresh: refreshToken,
// 		})

// 		if (res.status === 200) {
// 			// update the cookie
// 			Cookies.set('token', res.data.access, { expires: 10 })
// 			// update the headers
// 			req.headers.Authorization = `Bearer ${res.data.access}`
// 		}
// 	}

// 	return req
// })

const requestIntercept = api.interceptors.request.use(
	(config) => {
		if (config.headers['Authorization'] === null && token !== null) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	(error) => Promise.reject(error)
)

// const responseIntercept = api.interceptors.response.use(
// 	(response) => response,
// 	async (error) => {
// 		const prevRequest = error?.config
// 		if (error?.response?.status === 401 && !prevRequest.sent) {
// 			prevRequest.sent = true
// 			try {
// 				const res = await Axios.post(`${urls[process.env.NODE_ENV]}auth/token/refresh/`, {
// 					refresh: refreshToken,
// 				})
// 				prevRequest.headers['Authorization'] = `Bearer ${res.data.access}`
// 				Cookies.set('token', res.data.access, { expires: 1, secure: true })
// 				return api(prevRequest)
// 			} catch (err) {
// 				return prevRequest
// 			}
// 		}

// 		return Promise.reject(error)
// 	}
// )

export default api
