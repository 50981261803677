import { createContext, useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { format } from 'date-fns'
import Cookies from 'js-cookie'

import api from 'utils/api'

const TransactionContext = createContext({})

export const TransactionProvider = ({ children }) => {
	const router = useRouter()
	const [messages, setMessages] = useState({})
	const [acc, setAcc] = useState({})
	const [cur, setCur] = useState({})
	const [paym, setPaym] = useState({})
	const [sal, setSal] = useState({})
	const [exp, setExp] = useState({})
	const [loading, setLoading] = useState(true)
	const [trancItem, setTrancItem] = useState({})
	const [tokens, setTokens] = useState(() => {
		if (Cookies.get('token') && Cookies.get('refresh')) {
			return true
		} else {
			return false
		}
	})
	const need_company = Cookies.get('need_company')
		? JSON.parse(Cookies.get('need_company'))
		: false

	const lowInStock = async () => {
		let res = await api.get('inventory/lowstock/')

		if (res.status === 200) {
			if (res.data.length > 0) {
				setMessages({
					message: `Low In Stock!`,
					data: res.data,
					color: 'yellow',
					badge: 'Info',
				})
			}
		}
	}

	const createAccount = async (data) => {
		let res = await api.post(`transactions/createaccount/`, {
			name: data.name,
			number: data.number,
			bank_name: data.bank_name,
			opening_balance: data.opening_balance,
			currency: data.currency,
			payment_method: data.payment_method,
			enabled: data.enabled,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Account!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/accounts/')
		}
	}

	const account = async (id) => {
		let res = await api.get(`transactions/account/${parseInt(id)}/`)

		if (res.status === 200) {
			setAcc(res.data)
		}
	}

	const editAccount = async (data) => {
		let res = await api.put(`transactions/editaccount/${parseInt(data.acctId)}/`, {
			name: data.name === '' ? data.acc.name : data.name,
			number: data.number === '' ? data.acc.number : data.number,
			bank_name: data.bank_name === '' ? data.acc.bank_name : data.bank_name,
			opening_balance:
				data.opening_balance === ''
					? data.acc.opening_balance
					: parseFloat(data.opening_balance),
			currency: data.currency === '' ? data.acc.currency : parseInt(data.currency),
			enabled: data.enabled === '' ? data.acc.enabled : data.enabled,
			id: data.id,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Account!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading(false)
			router.push('/admin/accounts/')
		}
	}

	const deleteAccount = async (id) => {
		let res = await api.delete(`transactions/deleteaccount/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Account!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Currencies
	const createCurrency = async (data) => {
		let res = await api.post(`transactions/createcurrency/`, {
			name: data.name,
			rate: data.rate,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Currency!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/currencies/')
		}
	}

	const currency = async (id) => {
		let res = await api.get(`transactions/currency/${parseInt(id)}/`)

		if (res.status === 200) {
			setCur(res.data)
		}
	}

	const editCurrency = async (data) => {
		let res = await api.put(`transactions/editcurrency/${parseInt(data.currId)}/`, {
			name: data.name === '' ? data.curr.name : data.name,
			rate: data.rate === '' ? data.curr.rate : data.rate,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Currency!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/currencies/')
		}
	}

	const deleteCurrency = async (id) => {
		let res = await api.delete(`transactions/deletecurrency/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Currency!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Payment Methods
	const createPaymentMethod = async (data) => {
		let res = await api.post(`transactions/createpaymentmethod/`, {
			name: data.name,
			rate: data.rate,
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Payment Method!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/paymentmethods/')
		}
	}

	const paymentMethod = async (id) => {
		let res = await api.get(`transactions/paymentmethod/${parseInt(id)}/`)

		if (res.status === 200) {
			setPaym(res.data)
		}
	}

	const editPaymentMethod = async (data) => {
		let res = await api.put(`transactions/editpaymentmethod/${parseInt(data.paymId)}/`, {
			name: data.name === '' ? data.paym.name : data.name,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Payment Method!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/paymentmethods/')
		}
	}

	const deletePaymentMethod = async (id) => {
		let res = await api.delete(`transactions/deletepaymentmethod/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Payment Method!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Transactions
	const sale = async (id) => {
		let res = await api.get(`transactions/sale/${parseInt(id)}/`)

		if (res.status === 200) {
			setSal(res.data)
		}
	}

	const createSale = async (data) => {
		let res = await api.post(`transactions/createsale/`, {
			customer: {
				name: data.customer_name,
				phone: data.customer_phone,
			},
			quantity: data.quantity,
			account: data.account,
			payment_method: data.payment_method,
			currency: data.currency,
			description: data.description,
			amount: data.amount,
			paid_at: format(new Date(data.paid_at), 'yyyy-MM-dd'),
			products: data.transactionItems.map((item) => {
				return { name: item.product, quantity: item.quantity, discount: item.discount }
			}),
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Sale!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/transactions/sales/')
		}
		if (res.status === 200) {
			setMessages({
				message: res.data.message,
				color: 'red',
				badge: 'Error',
			})
			setLoading((loading) => !loading)
		}
	}

	const createExpense = async (data) => {
		let res = await api.post(`transactions/createexpense/`, {
			account: data.account,
			payment_method: data.payment_method,
			currency: data.currency,
			description: data.description,
			amount: parseFloat(data.amount),
		})

		if (res.status === 201) {
			setMessages({
				message: `Successfully Created Expense!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/transactions/expenses/')
		}
		if (res.status === 200) {
			setMessages({
				message: res.data.message,
				color: 'red',
				badge: 'Error',
			})
			setLoading((loading) => !loading)
		}
	}

	const expense = async (id) => {
		let res = await api.get(`transactions/expense/${parseInt(id)}/`)

		if (res.status === 200) {
			setExp(res.data)
		}
	}

	const transactionItem = async (id) => {
		let res = await api.get(`transactions/transactionitem/${parseInt(id)}/`)

		if (res.status === 200) {
			setTrancItem(res.data)
		}
	}

	const editExpenseTransaction = async (data) => {
		const date = data.paid_at ? format(new Date(data.paid_at), 'yyyy-MM-dd') : data.exp.paid_at
		let res = await api.put(`transactions/editexpensetransaction/${parseInt(data.expId)}/`, {
			id: data.exp.id,
			account: data.account === '' ? data.exp.account : data.account,
			payment_method:
				data.payment_method === '' ? data.exp.payment_method : data.payment_method,
			currency: data.currency === '' ? data.exp.currency : data.currency,
			description: data.description === '' ? data.exp.description : data.description,
			amount: data.amount ? parseFloat(data.amount) : parseFloat(data.exp.amount),
			paid_at: date,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Expense!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/transactions/expenses')
		}
		if (res.status === 201) {
			setMessages({
				message: res.data.message,
				color: 'red',
				badge: 'Error',
			})
			setLoading((loading) => !loading)
		}
	}

	const editSaleTransaction = async (data) => {
		const date = data.paid_at ? format(new Date(data.paid_at), 'yyyy-MM-dd') : data.sal.paid_at

		const getTransactionItemsIds = () => {
			let arr = []
			data.sal.products.map((obj) => {
				data.transactionItems.map((item) => {
					if (obj.product.name === item.product) {
						arr.push(obj.product.id)
					}
				})
			})
			return [...new Set(arr)]
		}

		let res = await api.put(`transactions/edittransaction/${parseInt(data.salId)}/`, {
			id: data.sal.id,
			account: data.account === '' ? data.sal.account : data.account,
			payment_method:
				data.payment_method === '' ? data.sal.payment_method : data.payment_method,
			currency: data.currency === '' ? data.sal.currency : data.currency,
			description: data.description === '' ? data.sal.description : data.description,
			paid_at: date,
			amount: data.amount === '' ? data.sal.amount : data.amount,
			transactionItems: data.transactionItems,
			// transactionItemsId: getTransactionItemsIds(),
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Transaction!`,
				color: 'emerald',
				badge: 'Success',
			})
			setLoading((loading) => !loading)
			router.push('/admin/transactions/sales/')
		}
		if (res.status === 201) {
			setMessages({
				message: res.data.message,
				color: 'red',
				badge: 'Error',
			})
			setLoading((loading) => !loading)
		}
	}

	const deleteTransaction = async (id) => {
		let res = await api.delete(`transactions/deletetransaction/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Transaction!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	const deletePreOrder = async (id) => {
		let res = await api.delete(`transactions/deletepreorder/${parseInt(id)}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Delete Pre Order!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	const contextData = {
		acc,
		paym,
		cur,
		sal,
		exp,
		messages,
		transactionItem,
		createSale,
		createExpense,
		sale,
		loading,
		expense,
		deleteTransaction,
		setMessages,
		editSaleTransaction,
		editExpenseTransaction,
		createAccount,
		editAccount,
		account,
		deleteAccount,
		currency,
		createCurrency,
		editCurrency,
		deleteCurrency,
		paymentMethod,
		createPaymentMethod,
		editPaymentMethod,
		deletePaymentMethod,
		deletePreOrder,
	}

	return <TransactionContext.Provider value={contextData}>{children}</TransactionContext.Provider>
}

export const useTransaction = () => useContext(TransactionContext)

export default TransactionContext
