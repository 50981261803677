import { createContext, useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { useSession } from 'next-auth/react'
import { signOut } from 'next-auth/react'

import api, { publicAPI } from 'utils/api'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
	const router = useRouter()
	const [countries, setCountries] = useState(null)
	const [user, setUser] = useState(null)
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isAccountant, setIsAccountant] = useState(false)
	const [isSupervisor, setIsSupervisor] = useState(false)
	const [isCustomerService, setIsCustomerService] = useState(false)
	const [tokens, setTokens] = useState(() => {
		if (Cookies.get('token') && Cookies.get('refresh')) {
			return {
				access: Cookies.get('token'),
				refresh: Cookies.get('refresh'),
			}
		} else {
			return null
		}
	})
	const [loading, setLoading] = useState(true)
	const [messages, setMessages] = useState({})
	const { data: session, status } = useSession()

	const need_profile = Cookies.get('need_profile')
		? JSON.parse(Cookies.get('need_profile'))
		: false
	const need_company = Cookies.get('need_company')
		? JSON.parse(Cookies.get('need_company'))
		: false

	useEffect(() => {
		if (status === 'authenticated') {
			setUpUser()
		}
		getCountries()
	}, [])

	async function setUpUser() {
		api.defaults.headers.Authorization = `Bearer ${session.accessToken}`
		// set the cookie
		Cookies.set('token', session.accessToken, {
			expires: 1,
			secure: true,
		})
		Cookies.set('refresh', session.refreshToken, {
			expires: 7,
			secure: true,
		})
		setIsAccountant(session.user.user.groups.map((group) => group.name === 'Accountant')[0])
		setIsCustomerService(
			session.user.user.groups.map((group) => group.name === 'CustomerService')[0]
		)
		setIsSupervisor(session.user.user.groups.map((group) => group.name === 'Supervisor')[0])

		Cookies.set('isAccountant', isAccountant)
		Cookies.set('isSupervisor', isSupervisor)
		Cookies.set('IsCustomerService', isCustomerService)
		const decoded_data = jwt_decode(session.accessToken)
		setUser({ ...session.user, username: decoded_data.username })
		setIsAuthenticated(true)
	}

	async function getCountries() {
		const loadCountries = async () => {
			let res = await publicAPI.get('countries/')

			if (res.status === 200) {
				setCountries(res.data)
			}
		}

		loadCountries()
	}

	// Next Url
	function setRedirect(redirect) {
		Cookies.set('redirectKey', redirect)
	}

	function getRedirect() {
		return Cookies.get('redirectKey') ? Cookies.get('redirectKey') : null
	}

	function clearRedirect() {
		return Cookies.remove('redirectKey')
	}

	const createProfile = async (e) => {
		e.preventDefault()

		let res = await api.put(`auth/updateprofile/${user.id}/`, {
			phone: e.target.phone.value,
			city: e.target.city.value,
			country: e.target.country.value,
		})

		if (res.status === 200) {
			if (!user.user.company_created) {
				router.push('/admin/createcompany')
			} else {
				router.push('/admin/companies')
			}
		} else {
			setMessages({
				message: "Something Went Wrong! Couldn't create your profile",
				color: 'red',
				badge: 'Error',
			})
		}
	}

	// Login
	const login = async (e) => {
		e.preventDefault()

		let res = await api.post(`auth/login/`, {
			username: e.target.username.value,
			password: e.target.password.value,
		})

		// if request was good (200) save the tokens
		if (res.status === 200) {
			const today = new Date()
			setTokens(res.data)
			// set the cookie
			Cookies.set('token', res.data.access, { expires: 1, secure: true })
			// Cookies.set('refresh', res.data.refresh, { expires: new Date(today.getDate() + 30) })
			Cookies.set('refresh', res.data.refresh, { expires: 7, secure: true })

			// Set the user
			const decoded_data = jwt_decode(res.data.access)

			api.defaults.headers.Authorization = `Bearer ${res.data.access}`

			let { data: user } = await api.get('auth/loggedinuser/')
			setUser({ ...user, username: decoded_data.username })
			setIsAuthenticated(true)

			let state = await api.get('auth/createstate/')

			if (!need_company) {
				// create the state for this user
				// This doesn't concern the frontEnd!
				let state = await api.get('auth/createstate/')
			}

			// if the user role is accountant set the 'IsAccountant'
			setIsAccountant(user.groups.some((group) => group.name === 'Accountant'))
			setIsCustomerService(user.groups.some((group) => group.name === 'CustomerService'))
			setIsSupervisor(user.groups.some((group) => group.name === 'SuperVisor'))

			if (need_profile && need_company) {
				setRedirect('/admin/createprofile')
			} else if (!need_profile && need_company) {
				setRedirect('/admin/createcompany')
			} else if (isAccountant) {
				if (need_profile) {
					setRedirect('/admin/createprofile')
				} else {
					setRedirect('/admin/accountantdashboard')
				}
			} else {
				const redirect = getRedirect()
				if (!redirect) {
					setRedirect('/admin/companies')
				}
			}

			setMessages({
				message: 'Successfully Logged In!',
				color: 'emerald',
				badge: 'Success',
			})
		} else {
			setMessages({ message: 'Something Went Wrong!', color: 'red', badge: 'Error' })
		}
	}

	// Register
	const register = async (e) => {
		e.preventDefault()

		// get rid of the authorazation header
		api.defaults.headers.Authorization = null

		try {
			let res = await api.post(`auth/register/`, {
				username: e.target.username.value,
				password: e.target.password.value,
				password2: e.target.password2.value,
				email: e.target.email.value,
				first_name: e.target.firstname.value,
				last_name: e.target.lastname.value,
			})

			// if request was good (200) save the tokens
			setMessages({
				message: `Successfully Created user ${res.data.username}!`,
				color: 'emerald',
				badge: 'Success',
			})
			// Set the new user flag
			Cookies.set('need_profile', true)
			Cookies.set('need_company', true)
			router.push('/auth/login')
		} catch (error) {
			console.log(error)
			setMessages({ message: 'Something Went Wrong!', color: 'red', badge: 'Error' })
		}
	}

	const registerAccountant = async (data) => {
		// get rid of the authorazation header

		api.defaults.headers.Authorization = ''

		let res = await api.post(`auth/registeraccountant/`, {
			username: data.username,
			password: data.password,
			password2: data.password2,
			email: data.email,
			first_name: data.first_name,
			last_name: data.last_name,
		})

		if (res.status === 200) {
			// if request was good (200) save the tokens
			setMessages({
				message: `Successfully Created user ${res.data.username}!`,
				color: 'emerald',
				badge: 'Success',
			})
			// Set the new user flag
			Cookies.set('need_profile', true)
			router.push('/auth/login')
		} else {
			setMessages({ message: 'Something Went Wrong!', color: 'red', badge: 'Error' })
		}
	}

	const deleteMember = async (id) => {
		let res = await api.delete(`auth/deleteuser/${id}/`)

		if (res.status === 204) {
			setMessages({
				message: `Successfully Removed Member!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.reload()
		}
	}

	// Logout
	const logout = (e) => {
		setLoading(false)
		setIsAuthenticated((isAuthenticated) => !isAuthenticated)
		setUser(null)
		clearRedirect()
		Cookies.remove('token')
		Cookies.remove('refresh')
		Cookies.remove('isAccountant')
		Cookies.remove('redirectKey')
		setMessages({
			message: 'Successfully Logged out!',
			color: 'emerald',
			badge: 'Success',
		})
		signOut()
		// router.push('/auth/login')
	}

	const editProfile = async (data) => {
		let res = await api.put(`auth/editprofile/${parseInt(data.profileId)}/`, {
			first_name: data.first_name,
			last_name: data.last_name,
			gender: data.gender,
			country: data.country,
			city: data.city,
			phone: data.phone,
		})

		if (res.status === 200) {
			setMessages({
				message: `Successfully Edited Your Profile!`,
				color: 'emerald',
				badge: 'Success',
			})
			router.replace('/admin/settings/')
		}
	}

	const contextData = {
		isAuthenticated,
		isAccountant,
		isSupervisor,
		isCustomerService,
		user,
		loading,
		tokens,
		messages,
		countries,
		editProfile,
		login,
		setLoading,
		setUser,
		setIsAuthenticated,
		setMessages,
		setIsAccountant,
		register,
		registerAccountant,
		createProfile,
		setUpUser,
		logout,
		setRedirect,
		getRedirect,
		clearRedirect,
		deleteMember,
	}

	return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

export default AuthContext
