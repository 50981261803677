import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import Cookies from 'js-cookie'

import { useAuth } from 'context/authContext'
import PageChange from './PageChange/PageChange'

export const ProtectRoute = ({ children }) => {
	const { setUpUser } = useAuth()
	const { status } = useSession()

	useEffect(() => {
		if (status === 'authenticated') {
			setUpUser()
		}
	}, [status])

	if (status === 'loading') {
		return <PageChange />
	}

	return <>{children}</>
}
