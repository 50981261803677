import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import NProgress from 'nprogress'
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SessionProvider } from 'next-auth/react'

import { AuthProvider } from 'context/authContext'
import { CompanyProvider } from 'context/companyContext'
import { TransactionProvider } from 'context/transactionContext'
import { TransactionProductProvider } from 'context/transactionProductContext'
import ReportingContextProvider from 'context/reportingContext'
import { ProtectRoute } from 'components/ProtectedRoute'

import { ToastContainer } from 'react-toastify'

import 'nprogress/nprogress.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/index.css'

const queryClient = new QueryClient()

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
		// Enable debug mode in development
		loaded: (posthog) => {
			if (process.env.NODE_ENV === 'development') posthog.debug()
		},
	})
}

const App = (props) => {
	const { Component, pageProps } = props
	const Layout = Component.layout || (({ children }) => <>{children}</>)

	const router = useRouter()

	useEffect(() => {
		const handleStart = (url) => {
			console.log(`Loading: ${url}`)
			NProgress.start()
		}

		const handleStop = () => {
			posthog?.capture('$pageview')
			NProgress.done()
		}

		router.events.on('routeChangeStart', handleStart)
		router.events.on('routeChangeComplete', handleStop)
		router.events.on('routeChangeError', handleStop)

		return () => {
			router.events.off('routeChangeStart', handleStart)
			router.events.off('routeChangeComplete', handleStop)
			router.events.off('routeChangeError', handleStop)
		}
	}, [router])

	return (
		<PostHogProvider client={posthog}>
			<SessionProvider session={pageProps.session}>
				<QueryClientProvider client={queryClient}>
					<Hydrate state={pageProps.dehydratedState}>
						<AuthProvider>
							<CompanyProvider>
								<ReportingContextProvider>
									<TransactionProvider>
										<TransactionProductProvider>
											<Head>
												<meta
													name='viewport'
													content='width=device-width, initial-scale=1, shrink-to-fit=no'
												/>
												<title>Xisaabiye | Accounting Saas</title>
											</Head>
											<Layout>
												<ToastContainer autoClose={3000} />
												{Component.requiresAuth ? (
													<ProtectRoute>
														<Component {...pageProps} />
													</ProtectRoute>
												) : (
													<Component {...pageProps} />
												)}
											</Layout>
										</TransactionProductProvider>
									</TransactionProvider>
								</ReportingContextProvider>
							</CompanyProvider>
						</AuthProvider>
					</Hydrate>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</SessionProvider>
		</PostHogProvider>
	)
}

export default App
