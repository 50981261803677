import { createContext, useState, useEffect, useContext } from 'react'
import Cookies from 'js-cookie'

import api from 'utils/api'

const ReportingContext = createContext({})

const ReportingContextProvider = ({ children }) => {
	const [dashboardData, setDashboardData] = useState({})
	const [headerStats, setHeaderStats] = useState({})
	const [loading, setLoading] = useState(true)
	const [lastMonthRevenue, setLastMonthRevenue] = useState({})
	const isAccountant = Cookies.get('isAccountant')
	const [tokens, setTokens] = useState(() => {
		if (Cookies.get('token') && Cookies.get('refresh')) {
			return {
				access: Cookies.get('token'),
				refresh: Cookies.get('refresh'),
			}
		} else {
			return null
		}
	})
	const need_company = Cookies.get('need_company')
		? JSON.parse(Cookies.get('need_company'))
		: false

	useEffect(() => {
		if (tokens === true) {
			if (need_company === false) {
				getDashboardData()
				// getProfitReport()
			}
		}
	}, [loading])

	const getDashboardData = async () => {
		if (isAccountant !== 'true') {
			let res = await api.get('reports/mdashboard/')

			if (res.status === 200) {
				setHeaderStats(res.data)
				setLoading(false)
			}
		}
	}

	const getProfitReport = async () => {
		if (isAccountant !== 'true') {
			let res = await api.get('reports/profits/')

			if (res.status === 200) {
				setLastMonthRevenue(res.data)
				return res.data
				setLoading(false)
			}
		}
	}

	const context = {
		dashboardData,
		getProfitReport,
		headerStats,
		lastMonthRevenue,
	}

	return <ReportingContext.Provider value={context}>{children}</ReportingContext.Provider>
}

export const useReporting = () => useContext(ReportingContext)

export default ReportingContextProvider
